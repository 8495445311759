import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import Panel from '@/components/common/Panel';
import NoDataFound from '@/components/common/NoDataFound';

import { isKeyOf, MDASH } from '@/helpers';

import { experimentSelectors } from '@/store/slices/experiment';

import { themeOptions } from '@/types/theme';

import settingsBlockStyles from '../Settings/components/SettingBlock/SettingBlock.module.scss';
import settingsStyles from '../Settings/Settings.module.scss';
import tableStyles from '../Settings/components/SettingValueTable/SettingValueTable.module.scss';
import styles from './Consumables.module.scss';

const cn = classnames.bind({ ...tableStyles, ...settingsBlockStyles, ...settingsStyles, ...styles });

const Consumables: FC = () => {
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);

  const consumables = useMemo(() => experimentData?.consumables ?? {}, [experimentData?.consumables]);

  if (!Object.keys(consumables).length) {
    return <NoDataFound size="normal" alignment="center" />;
  }

  return (
    <div className={cn('settings')}>
      <Panel theme={themeOptions.light}>
        <Panel.Content className={cn('settings__content', 'panel__content')}>
          {Object.keys(consumables).map((key) => (
            <div key={key} className={cn('settings-block', 'content-block')}>
              <div className={cn('settings-block__title', 'content-block__title')}>{key}</div>
              <div className={cn('settings-block__text-content')}>
                <table key={key} className={cn('table')}>
                  <thead className={cn('table__head')}>
                    <tr className={cn('table__row', 'table__row_no-underline')}>
                      <th className={cn('table__heading')}>Consumable name</th>
                      <th className={cn('table__heading')}>Consumable type</th>
                      <th className={cn('table__heading')}>Expiration date</th>
                      <th className={cn('table__heading')}>Override used</th>
                    </tr>
                  </thead>
                  <tbody className={cn('table__body')}>
                    {isKeyOf(key, consumables) &&
                      consumables?.[key]?.map((consumable) => (
                        <tr key={consumable.consumableId} className={cn('table__row')}>
                          <td className={cn('table__data-element')}>
                            <span>{consumable?.instanceId ?? MDASH}</span>
                          </td>
                          <td className={cn('table__data-element')}>
                            <span>{consumable?.consumableType ?? MDASH}</span>
                          </td>
                          <td className={cn('table__data-element')}>
                            <span>{consumable?.expirationDate ?? MDASH}</span>
                          </td>
                          <td className={cn('table__data-element')}>
                            <span>{consumable.userOverride ? 'Yes' : 'No'}</span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </Panel.Content>
      </Panel>
    </div>
  );
};

export default Consumables;
